* {
    font-family: 'Poppins', sans-serif;
}
html {
  height: 100%;
}
.full-height{height: 100vh !important;}
body {
    min-height: 100%;
    position: relative;
}
body.subscriptionPage {
  padding-bottom: 100px;
}
body p {
    font-size: 14px;
    line-height: normal;
}
p:empty {
  display: none;
}
body a {
    color: #94c23c;
}
.video-popup{
      background-position: top right !important;
    background-repeat: no-repeat !important;
    background: url(/images/header-circle.png);
}
.videoOuter{height: 100% !important;}
.readmore_text{
  color:#98cb36;
  font-size: 17px !important;
  cursor:pointer;
}
.navbar.navbar-transparent a:not(.dropdown-item):not(.btn) {
    color: #66615B !important;
}
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
/*font-family: 'Source Sans Pro', sans-serif;*/
body .text-primary,
body a.text-primary:focus,
body a.text-primary:hover {
    color: #2e5f9c !important;
}
h3:empty,
h5:empty {
  display: none;
}
.btn1{
  margin: 0 6px;
    font-weight: normal;
    padding: 9px 25px;
    border: 1px solid transparent;
}
.cancel_sub_div{
  text-align: right;
  margin-bottom: 15px;
}
.btn-danger{background: red !important;}
.wrong-blank{
  background: red;
    color: white;
}
.srv-validation-message{
  color: red;
}
.react-datepicker-wrapper{width: 100%}
.sidebar-mini .sidebar:hover .sidebar-wrapper {
    width: 80px !important;
}
.no-border{border-radius: 0px !important;}
.sidebar-mini .sidebar:hover {
    width: 80px !important;
}

.right-blank{
  background: green;
    color: white;
}
.red-line {
    background-color: red;
}.blue-line {
    background-color: blue;
}
.timeout-box{
  border: green ridge 2px;
    padding: 5px;
}
.correct-line > input[type="checkbox"]:checked {
    background-color: green;
}
.comment-user{margin: 0px !important;color: green !important;}
.audio_script_text{border: 1px black ridge;padding: 11px;}
.audio_script_button{padding: 8px;}
table a {
  color: #94C23C
}
.green-text{
  color: #94C23C;
}
.audio-line{
  border:1px solid #777777;
  margin-top:50px;
  width: 10%
}

.speach-text:hover{
  background-color: blue;
  color: white;
}
.form-check-input{
  position: inherit !important;
    margin-left: 0 !important;
}
.highlight{
  background-color: #01ff01;
  cursor: pointer;
}
.div1, .div2 {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
.span1 {
    min-width: 80px;
    padding: 4px 15px;
    margin: 0px 4px 10px;
    border: 1px solid #a2a2a2;
    min-height: 26px;
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
}
.anwrsOptions span {
    min-width: 80px;
    padding: 4px 15px;
    margin: 0px 4px 10px;
    border: 1px solid #a2a2a2;
    min-height: 32px;
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
    /*text-transform: capitalize;*/
}
h5 select {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 8px;
    margin: 0 4px 8px;
    font-size: 15px;
    border: 1px solid #a2a2a2;
    cursor: pointer;
}
.correct-line,.correct,
.highlight {
    background-color: #94C23C;
    color: #fff;
    cursor: pointer;
}
.MuiRadio-colorPrimary.Mui-checked,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #94C23C !important;
}
.alert {
  white-space:pre-wrap;
  cursor: pointer;
}
/*.sc-AykKF{
  min-height: 200px !important;
}
.iirsUy {
  border: 0px !important;
}
div[data-testid="card"] {
  width: 500px !important;
  min-width: 500px !important;
}
.jcydoa .hNyPoC{
  min-width: 500px !important;
}*/
.content .card {
  border-radius: 3px !important;
  box-shadow: rgba(51, 51, 51, 0.08) 0px 4px 10px;
  border-top: 15px solid #1d195b;
  color: #505050;
}
.content.multipleCards .card {
  border: none !important;
  background: transparent;
  box-shadow: none;
}
.content.multipleCards .card .card-body {
  padding: 12px 12px 0 !important;
}
.content.multipleCards .alert.alert-primary {
  background-color: rgba(53, 220, 216,0.25);
  color: #1e9693;
}
.content.multipleCards .alert.alert-warning {
  background-color: rgba(84, 214, 248,0.25);
  color: #337182;
}
.content.multipleCards .alert.alert-info {
  background-color: rgba(127, 143, 236,0.25);
  color: #2d3fa9;
}
.content.multipleCards .alert.alert-success {
  background-color: rgba(250, 153, 157,0.25);
  color: #a94044;
}
.badge {
  margin-bottom: 0 !important;
  margin-left: 5px;
  position: relative;
  top: -2px;
}
.content.multipleCards .alert.alert-primary .badge {
  color: #1e9693;
  background: white !important;
}
.content.multipleCards .alert.alert-warning .badge {
  color: #337182;
  background: white !important;
}
.content.multipleCards .alert.alert-info .badge {
  color: #2d3fa9;
  background: white !important;
}
.content.multipleCards .alert.alert-success .badge {
  color: #a94044;
  background: white !important;
}
body .table {
  color: #010101;
}
.questionsArea .infoText {
  margin: 0 0 20px;
  font-size: 15px;
  font-weight: bold;
  color: #5d5d5d;
  font-style: italic;
}
.questionsArea h5 {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  margin-top: 0;
  line-height: 24px;
}
.questionsArea h5 b {
  font-weight: bold;
  font-style: italic;
  font-size: 15px;
}
.currentStatus {
/*  border: 1px solid #b0b0b1;
  max-width: 500px;
  margin: 25px auto;
  height: 195px;
  padding: 5px;*/
  text-align: center;
}
.audio-box {
    width: 340px;
    margin: 20px auto 15px !important;
    padding: 10px 5px 50px !important;
    height: auto;
    border: 1px solid #b0b0b1;
    background: #ffffff;
    text-align: left;
    position: relative;
}
.card {
  position: relative;
}
.scoreLoader.showLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;    
    display: flex;
    align-items: center;
    justify-content: center;
}
.card .showLoader:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}
.card .showLoader:after {
    content: "\f021";
    position: absolute;
    font-family: FontAwesome;
    left: 0;
    right: 0;
    margin: auto;
    color: #94c23c;
    font-size: 28px;
    text-align: center;
    -webkit-animation: fa-spin 0.8s infinite linear;
    animation: fa-spin 0.8s infinite linear;
    top: 50%;
    margin-top: -54px;
}
.correct-anss{ background-color: green !important; }
.correct-anss:after{
  content: "\f00c";
    font-family: FontAwesome;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    font-size: 19px;
    text-align: center;
    -webkit-animation: fa-spin 0.8s infinite linear;
    animation: fa-spin 0.8s infinite linear;
    top: 50%;
    margin-top: -54px; 
}
.wrong-anss{background-color: red !important;}
.blue-bg{background-color: blue;color: white;}
.wrong-anss:after{
  content: "\f00d";
    font-family: FontAwesome;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    font-size: 19px;
    text-align: center;
    -webkit-animation: fa-spin 0.8s infinite linear;
    animation: fa-spin 0.8s infinite linear;
    top: 50%;
    margin-top: -54px; 
}
.scoreLoader.showLoader span {
  position: relative;
  font-size: 15px;
  font-weight: bold;
}
.audio-box strong {
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
}
.audio-box p {
  margin-bottom: 5px;
}
.audio-box p:first-of-type {
  margin-bottom: 12px;
}
.audio-box h4 {
  color: #3e4248;
  margin: 0 0 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.audio-box hr {
  display: none;
}
.questionsArea .question {
  font-size: 20px;
  margin: 5px 0 10px;
  font-weight: 500;
}
.answers label {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 0 !important;
  color: #000 !important;
  font-size: 18px !important;
}
.answers label.correct-line {
  color: #fff !important;
}
h5 input {
  margin: 0 5px 12px;
}
.sc-fznKkj,
.sc-AxjAm {
  width: 100%;
}
.sc-AxiKw {
  width: 48.5%;
  margin: 0 12px !important;
}
.sc-fzoLsD {
  max-width: 100% !important;
}
.fnyorP {
  max-width: 40% !important;
}
.sc-AxhCb {
  min-height: 205px !important;
}
.sc-AxhCb > div {
  width: 100% !important;
}
.sc-fzoLsD span {
  display: none;
}
.krVTcw {
  padding:0 !important;
}
.bottomBtns {
  background: #fff;
  padding: 18px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}
.bottomBtns div[class*="col-"]:last-child {
    text-align: right;
}
.currentStatus .btn {
    text-transform: none;
    padding: 6px 12px !important;
}
.bottomBtns button.btn, button.btn,
#examDatePopup .card-footer .btn,
a.btn {
  background: #94C23C;
  margin: 0 6px;
  font-weight: bold;
  padding: 12px 32px;
  border: 1px solid transparent;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}
.bottomBtns button.btn:hover,
button.btn:hover,
.bottomBtns button.btn:focus,
button.btn:focus,
button.btn:active,
.bottomBtns button.btn:active,
body .btn-info:active,
body .btn-info:focus,
#examDatePopup .card-footer .btn:hover,
#examDatePopup .card-footer .btn:active,
#examDatePopup .card-footer .btn:focus,
a.btn:hover,
a.btn:active,
a.btn:focus {
  background: #fff !important;
  color: #94C23C !important;
  border-color: #94C23C !important;
}
.btn.cancelBtn {
  background: #ff0000 !important;
}
#examDatePopup .card-footer .btn.cancelBtn:hover,
#examDatePopup .card-footer .btn.cancelBtn:active,
#examDatePopup .card-footer .btn.cancelBtn:focus {
  background: #fff !important;
  color: #ff0000 !important;
  border-color: #ff0000 !important;
}
.paginationOuter,
.nextPrevOuter {
  margin: 15px -6px 8px;
  text-align: right;
}
.paginationOuter button.bottom-btn,
.nextPrevOuter button {
    padding: 8px 14px;
}
.videosBlock iframe {
  height: 300px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #94c23c;
  padding: 5px;
  box-shadow: 0 10px 17px -10px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 10px 17px -10px rgba(0, 0, 0, 0.4);
  margin-bottom: 12px;
}
.videosBlock {
    margin-bottom: 30px;
}
.progress {
  background: rgba(148, 194, 60,0.4) !important;
  border-radius: 0 !important;
  border-left: 0 solid #000;
  border-top: 0 solid #000;
  height: 12px !important
}
.progress-bar.bg-info {
    background-color: #94c23c !important;
}
td.videoLink a {
  padding: 0;
}
.videoOuter > div {
  margin: auto auto 40px;
}
.videoComnts {
  
  margin: auto;
}
.commentArea textarea.form-control, .commentArea button.btn {
  display: inline-block;
  vertical-align: middle;
}
.form-group .form-control, .input-group .form-control {
  height: auto !important;
}
.form-group input[type=file].form-control {
  position: inherit;
  opacity: 1;
}
.commentArea textarea.form-control {
  width: 82%;
  padding: 3px 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 1px !important;
}
textarea.form-control {
  width: 100%;
  padding: 10px 10px 0 0;
  border: none;
  border: 1px solid #E3E3E3 !important;
  border-radius: 4px;
  line-height: 2;
  min-height: 55px;
  height: auto;
  overflow: auto;
  font-family: 'Poppins', sans-serif !important;
}
.commentArea button.btn {
  height: 64px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  padding: 11px 22px;
  border: none;
}
.commentArea button.btn:hover,
.commentArea button.btn:focus {
    background: #87af3a !important;
    color: #fff !important;
}
.postedComments {
    margin-top: 30px;
}
.pComment {
    float: left;
    width: 100%;
    position: relative;
}
.pComment button.btn.btn-outline-danger {
    margin: 0;
    border: none !important;
    background: transparent !important;
}
.pComment button.btn.btn-outline-danger:hover {
  background: transparent !important;
  color: #ef8157 !important;
}
.pComment p,
.pComment button {
    display: inline-block;
    vertical-align: top;
}
.pComment p {
    width: 86%;
}
/*subscriptionPage css start*/
.subscriptionPage {
    display: block;
    width: 100%;
    padding-top: 63px;
}
.card-title h2 {
    font-size: 27px;
    margin: 0 0 5px;
    text-transform: uppercase;
}
.card-title .subtitle {
  font-size: 17px;
}
.subscriptionPage nav.navbar-absolute {
    background: #222222 !important;
    border-color: transparent !important;
}
.subscriptionPage nav.navbar-absolute a {
    color: #fff !important;
}
.subsBanner {
    height: 860px;
    overflow: hidden;
    background-image: url(https://www.languageacademy.com.au/a/web/images/banners/video-courses-banner.png);
    background-size: cover;
    background-position: center -110px;
    position: relative;
}
.subsBanner:after {
  content: "";
  background-color: rgba(0,0,0,.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bannerContent {
    color: #fff;
    position: relative;
    padding-top: 440px;
    z-index: 999;
    text-align: center;
}
.bannerContent h2 {
    text-transform: uppercase;
    font-size: 32px;
    margin: 0 0 230px;
}
.bannerContent h2 span {
  display: block;
  width: 100%;
  font-size: 18px;
  margin-top: 15px;
}
.downArrow {
  font-size: 60px;
  display: inline-block;
  vertical-align: top;
  left: 0;
  right: 0;
  margin: auto;
  color: #FFF;
  z-index: 9;
  text-align: center;
  width: 70px;
  height: 70px;

  border-radius: 50%;
  line-height: 73px;
}
.downArrow:hover,
.downArrow:focus {
  color: #FFF;
}
.whyCourse {
  padding: 90px 0 105px;
}
.whyCourse h2,
.subsPackages h2 {
    color: #94c23c;
    font-size: 34px;
}
.whyCourse ul {
    list-style: none;
    padding: 0;
}
.whyCourse li {
    position: relative;
    padding-left: 32px;
    margin: 0 0 10px;
}
.whyCourse li:before {
    content: "\f101";
    font-family: FontAwesome;
    height: 20px;
    width: 20px;
    background: #94c23c;
    color: #fff;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    left: 0;
    position: absolute;
}
.whyCourse iframe {
    width: 555px;
    height: 315px;
    border: none;
}
.whyCourse .subsPackages {
  background: #FBFBFB;
  padding: 95px 0;
}
.subsPackages {
    background: #FBFBFB;
    padding: 95px 0;
}
.planDescInr {
  background: #F6F6F6;
  transition: 0.5s all;
}
.planDesc:hover .planDescInr {
    box-shadow: 0 0 10px #ccc;
    transform: scale(1.3);
    z-index: 99;
    position: relative;
}
.planDesc.features:hover .planDescInr {
  box-shadow: none;
  transform: none;
}
.features.planDesc {
    text-align: left;
}
.planDesc {
    text-align: center;
    font-weight: bold;
}
.features.planDesc .planHead {
  background: #f6f6f6;
  color: #000;
}
.planDesc .planHead {
  padding: 20px 10px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  background: #94C23B;
  color: #fff;
}
.planBody ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.planBody ul li {
    padding: 15px 10px;
    font-weight: 500;
}
.planBody ul li:nth-child(odd) {
    background: #DFE6F0;
}
.planDesc.features .planFooter {
  background: #eae7e7;
  font-size: 20px;
  font-style: italic;
}
.planDesc .planFooter {
  background: #E5B326;
  position: relative;
}
.planDesc .planFooter a {  
  color: #fff;
  font-size: 18px;
  font-style: italic;
  text-transform: uppercase;
  display: block;
  padding: 20px 15px;
  position: relative;
  z-index: 99;
}
.planDesc .planFooter a:hover {  
  text-decoration: none;
}
.planDesc .planFooter a strong {  
  display: block;
  width: 100%;
  position: relative;
  font-size: 40px;
}
.planDesc .planFooter a strong:before {
  content: "$";
  display: inline-block;
  vertical-align: middle;
  font-size: 55px;
  line-height: 50px;
  margin-top: -4px;
  font-weight: 500;
}
.planDesc.features .planFooter {
  padding: 20px 15px;
}
.planDesc.features .planFooter:before {
  display: none;
}
.planDesc .planFooter:before {
  content: "";
  width: 100%;
  height: 48px;
  background-color: rgba(0,0,0,.15);
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}
.planDesc .planFooter:hover:before {
    height: 100%;
}
.card-body .alert {
    background: transparent;
    border: 1px solid transparent;
    font-weight: 500;
    color: #fff;
    transition: all 0.5s;
}
.card-body .alert:hover {
    background: #94c23c;
    color: #fff;
}
.speakingZone .alert.alert-success {
    background: #FFDA83;
    border-color: #e4c16e;
    color: #fff;
}
.writingZone .alert.alert-success {
    background: #55D8FE;
    color: #fff;
    border-color: #329fbf;
}
.readingZone .alert.alert-success {
    background: #A3A0FB;
    border-color: #7471cc;
}
.pteInfo ul{
  display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    list-style-type: none;
}

.pteInfo ul li:before {
    content: "\25CF";
    color: #274d86;
    padding-right: 15px;
}
.listeningZone .alert.alert-success {
    background: #FF8373;
    border-color: #cc6053;
}
.videoOuter {
    background: url('/load.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    min-height: 185px;
}
.sidebar:after, .off-canvas-sidebar:after {
  background: #1d195b !important;
}
.sidebar .nav i, .off-canvas-sidebar .nav i {
    font-size: 22px !important;
    color: rgba(255, 255, 255, 0.7);
}
.wrapper .sidebar .nav i, .off-canvas-sidebar .nav i {
    position: relative;
    left: 0;
    top: -1px;
}
.wrapper .sidebar .nav li:hover i, .off-canvas-sidebar .nav li:hover i,
.wrapper .sidebar .nav li.active i, .off-canvas-sidebar .nav li.active i {
    background: transparent;
    color: #323d48;
}
.sidebar .nav p, .off-canvas-sidebar .nav p {
    font-weight: 400 !important;
    font-size: 17px;
}
.sidebar .user {
  display: flex;
  flex-direction: row;
}
.sidebar .user,
.off-canvas-sidebar .user {
  margin: auto !important;
  border-top: 1px solid #323d48;
  border-bottom: 1px solid #323d48;
  padding: 25px 22px !important;
}
.sidebar .user:after,
.off-canvas-sidebar .user:after,
.sidebar .logo:after,
.off-canvas-sidebar .logo:after {
  display: none;
}
.sidebar .user .photo, .off-canvas-sidebar .user .photo {
  width: 62px !important;
  height: 62px !important;
  margin: auto !important;
  text-align: center;
  border-radius: 2px !important;
  float: none !important;
  overflow: hidden;
}
.sidebar .user .photo img,
.off-canvas-sidebar .user .photo img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0;
  object-fit: cover;
  object-position: top center;
}
.sidebar .user .info > a > span,
.off-canvas-sidebar .user .info > a > span {
    text-transform: capitalize;
    letter-spacing: 1px;
}
.sidebar[data-active-color="info"] .nav li.active > a, .sidebar[data-active-color="info"] .nav li.active > a i, .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, .sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a i, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, .off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
  color: #162545 !important;
}
.sidebar .user .info .caret,
.off-canvas-sidebar .user .info .caret {
  right: inherit !important;
  position: relative !important;
  top: inherit !important;
  margin-left: 8px;
}
.info {
    width: 70%;
    margin-left: auto;
}
.sidebar .user .info > a,
.off-canvas-sidebar .user .info > a {
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 0 !important
}
.sidebar .nav,
.off-canvas-sidebar .nav {
    margin-top: 0 !important;
}
.error-alert .alert{
  text-align: left;
}
.unselectable { 
  -webkit-user-select: none; 
  -webkit-touch-callout: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
}
#root .wrapper-full-page {
  height: 100vh;
}
.footer {
  padding: 24px 0;
  position: relative;
  width: 100%;
  bottom: 0;
}
.subPaymentPage h4 {
  text-align: center;
}
.subPaymentPage label {
    font-weight: 400;
    color: #676767;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
#root .subPaymentPage .panel-body .row {
  margin-bottom: 15px;
}
#root .subPaymentPage .panel-body .row .input-group input {
  border-right: 1px solid #c5c5c5;
}
#root .subPaymentPage .panel-body .row .input-group .input-group-addon {
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 8px;
}
.subPaymentPage input::-webkit-input-placeholder,
.subPaymentPage select::-webkit-input-placeholder,
.subPaymentPage select option:first-child {
  color: #cbcbcb;
  opacity: 1;
}
.subPaymentPage input:-ms-input-placeholder,
.subPaymentPage select:-ms-input-placeholder,
.subPaymentPage select option:first-child {
  color: #cbcbcb;
  opacity: 1;
}
.subPaymentPage input::placeholder,
.subPaymentPage select::placeholder,
.subPaymentPage select option:first-child {
  color: #cbcbcb;
  opacity: 1;
}
#root .subPaymentPage input, #root .subPaymentPage select {
    border-radius: 15px;
}
.subPaymentPage button.btn {
    background: #1d195b;
    border-radius: 20px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 400;
    cursor: pointer;
}
.subPaymentPage button.btn:hover,
.subPaymentPage button.btn:active,
.subPaymentPage button.btn:focus {
    border-color: #1d195b !important;
    color: #1d195b !important;
    background: transparent !important;
}
.successContent {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
}
.successContent i {
  font-size: 60px;
  border: 2px solid #94c23c;
  border-radius: 50%;
  height: 125px;
  width: 125px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
  color: #94c23c;
}
.formBottomImg {
  text-align: center;
}
.answers span[class*="PrivateSwitchBase-root-"] {
  padding-left: 0;
}
.bottomBtns select {
  padding: 8px 12px;
  min-width: 100px;
  margin-top: 6px;
  border-radius: 4px;
}
.bottomBtns select:focus {
  outline: 0;
}
.highlight_question{
  cursor: pointer;
}
.stop-btn{
  margin: 10px;
  text-align: center;
}
.result-div {
  border: 1px #94c23c ridge;
  width: 100%;
  padding: 50px 15px;
  text-align: center;
}
.card .card-body {
  padding: 20px 20px 10px !important;
}
.copyPaste {
  text-align: center;
}
.copyPaste a.btn:last-child {
  float: right;
}
.copyPaste a.btn:first-child {
  float: left;
}
.copyPaste a.btn {
  margin: 0;
  background: linear-gradient(#ECECEC, #DCDCDC);
  background: -webkit-linear-gradient(#ECECEC, #DCDCDC);
  color: #000;
  border: 1px solid #d5d5d5;
  padding: 5px 4px 2px;
  text-transform: capitalize;
}
.questionTime p {
  margin: 4px 0 0;
  font-size: 13px;
}
.timeRemaining i {
  margin-right: 2px
}
.questionsArea .sc-Axmtr span {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.questionsArea .sc-AxiKw  {
  background: transparent;
  margin: 0 !important;
  padding-right: 85px;
  position: relative;
}
.questionsArea .sc-AxhCb {
    border: 1px solid #1e9693;
    border-radius: 3px;
}
.questionsArea .sc-fzoLsD {
    border-radius: 0;
    padding: 8px;
    font-size: 14px;
    margin: 2px;
    border: 1px solid #1e9693;
    background: rgba(53, 220, 216,0.25);
    color: #1e9693;
}
/*.questionsArea .sc-fznKkj .sc-AxiKw:after,
.questionsArea .sc-fznKkj .sc-AxiKw:before {
  content: "\f0a9";
  font-family: fontawesome; 
  top: 41%;
  position: absolute;
  right: 30px;
  font-size: 20px;
  color: #B0B0B0;
}
.questionsArea .sc-fznKkj .sc-AxiKw:before {
  top: 56%;
  transform: rotate(180deg);
}
.questionsArea .sc-fznKkj .sc-AxiKw:last-child:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.questionsArea .sc-fznKkj .sc-AxiKw:last-child:after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}*/

.text-right{
  text-align: right;
}
.viewResultbtn {
  margin-left:10px !important;
}
.modalCloseBtn{margin: 10px 5px !important;padding: 8px !important;}
/*result*/
.container {max-width: 1170px;margin: 0 auto;padding: 0 15px;}
.title-bar {background: #94c23c;color: #fff;font-size: 22px;padding: 5px 10px;min-width: 200px;display: table;font-weight: 500;margin: 10px 0;}
.section-full, .full-width{float: left;width: 100%;}
.left-panel ul, .skill-left ul {list-style: none;margin: 0;padding: 0;}
.left-panel, .skill-left {float: left;width: 200px;}
.left-panel .label{float: left;}
.skill-left .label {float: left;width: 100%;min-height: 30px;line-height: 30px;}
.left-panel .value, .skill-left .value {float: right;}
.left-panel ul li, .skill-left ul li{float: left;width: 100%;margin: 0 0 6px;}
.left-panel h3{font-size: 18px;font-weight: 500;margin: 5px 0;}
.skill-left h3{font-size: 18px;font-weight: 500;margin: 5px 0;text-align: right;}
.skill-right h3{font-size: 18px;font-weight: 500;margin: 5px 0;text-align: center;float: left;width: 100%;}
.left-panel h3.enable-skills-title, .skill-left h3.enable-skills-title {margin: 0 0 12px !important;float: left;width: 100%;}

.skill-left {text-align: right;}
.left-panel {padding-top: 5px;padding-bottom: 5px;}
.right-panel{float: right;width: calc(100% - 220px);background: rgba(53, 220, 216,0.25);padding:5px 20px 40px;border: 3px solid #1e9693;}
.skill-right {float: right;width: calc(100% - 220px);position: relative;}
.chart-ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.chart-ul li {
  border: 3px solid #1e9693;
  height: 30px;
  margin-bottom: 6px;
}
.comm-skills li{background: #1e9693;}
.enable-skills li{background: #b1ded7;}
.skill-right:before {
  content: "";
  height: calc(100% - 40px);
  background: #1e9693;
  width: 3px;
  position: absolute;
  left: 0;
  top: 40px;
}
.skill-right::after {
  content: "";
  background: #1e9693;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
}
.points {
    float: left;
    width: 100%;
    list-style: none;
    position: relative;
    top: 4px;
    left: -3px;
    padding-left: 10px;
    margin: 0px;
}
.points li {
  float: left;
  border-left: 3px solid #1e9693;
  height: 5px;
  width: 0;
  margin-left: .6%;
}
.numbered-ul {
  float: left;
  width: 100%;
  margin: 0;
  list-style: none;
  position: absolute;
  bottom: -30px;
  padding-left: 0;
}
.numbered-ul li {
  float: left;
  margin: 0 .6% 0 0;
  font-size: 14px;
  min-width: 23px;
}
.points li:nth-child(4n) {
  height: 8px;
  position: relative;
  top: -1px;
}
.deviceTestPage .successContent {
  padding-top: 10px;
}
.testingImgOuter {
  width: 150px;
  height: 100px;
  overflow: hidden;
  float: right;
  max-width: 100%;
  /*border: 5px solid #94c23c;*/
}
.testingImgOuter img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}
.deviceTestPage .currentStatus {
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}
.deviceTestPage .row {
    width: 100%;
}
.deviceTestPage h4,
.card .card-header .card-title,
.innerTitle {
    font-size: 27px;
    font-weight: 500;
    line-height: 30px;
    margin: 0 !important;
}
.card .card-header h4 {
    margin: 0;
    position: relative;
}
.deviceTestPage h6,
.card-body h6 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
}
.successContent ol {
    padding-left: 15px;
}
.deviceTestPage ul {
    padding-left: 18px;
}
.deviceTestPage li {
    font-size: 14px;
    margin-bottom: 10px;
}
.note {
  /*background: rgba(250, 153, 157,0.25);
  color: #a94044;*/
  padding: 14px;
}
.note ul {
    margin: 0;
}
.main-panel {
  background: #d8ebfa !important;
}
body .text-warning,
body a.text-warning:focus,
body a.text-warning:hover,
body .text-success,
body a.text-success:focus,
body a.text-success:hover {
  color: #94c23c !important;
}
body .table > thead > tr > th,
body .table > tbody > tr > th,
body .table > tfoot > tr > th,
body .table > thead > tr > td,
body .table > tbody > tr > td,
body .table > tfoot > tr > td {
  padding: 20px 7px;
}
.videosContent .alert-danger {
  text-align: center;
}
body .alert.alert-danger {
  background-color: rgba(250, 153, 157,0.25);
  color: #a94044;
}
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after,
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before,
.sidebar .sidebar-wrapper li.active > [data-toggle="collapse"] + div .nav li:before {
  border-right-color: #fafafa !important;
}
.wrapper .sidebar .sidebar-wrapper > .nav li,
.wrapper .sidebar .sidebar-wrapper > .nav li a {
  position: relative;
}
.wrapper .sidebar .sidebar-wrapper > .nav li:nth-last-child(2) {
    margin-top: 60px;
}
.wrapper .sidebar .sidebar-wrapper > .nav li#collap-menu li:nth-last-child(2) {
    margin: 0;
}
.wrapper .sidebar .sidebar-wrapper > .nav li:hover {
    color: #162545;
}
.sidebar .sidebar-wrapper > .nav li > a:before,
.sidebar .sidebar-wrapper > .nav li.active:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: #b9ed2e;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
.sidebar .sidebar-wrapper > .nav li:hover > a:before,
.sidebar .sidebar-wrapper > .nav li.active:before {
    width: 95%;
    opacity: 1;
    transition: all 0.4s ease-in-out 0s;
}
.wrapper .sidebar .sidebar-wrapper > .nav li .nav li:hover a, .wrapper .sidebar .sidebar-wrapper > .nav li .nav li:hover a i {
    color: #fff;
}
.sidebar .nav .caret, .off-canvas-sidebar .nav .caret {
    top: 7px !important;
}
.questionsArea .result-div h5 {
    text-align: center;
}
.wrapper .sidebar .nav li > a + div {
    background: #1e2c46;
    padding: 4px 4px 4px 14px;
}
.wrapper .sidebar .nav li > a + div i {
    height: 21px;
    width: 25px;
    font-size: 12px !important;
    line-height: 25px;
    top: 2px;
}
.result-div h3 {
  font-size: 20px;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.result-div .viewResultbtn {
    margin-top: 15px;
}
.result-div > div {
  margin-bottom: 32px;
}
.result-div > div:last-child {
  margin-bottom: 0;
}
.table .nav-link {
    padding: 0;
}
body .modal-footer {
    padding: 12px;
}
audio:focus {
  outline: 0;
}
.scoreContent h4 {
    margin-bottom: 5px;
}
.scoreContent p {
    font-size: 16px;
}
.scoreLoading, .scoreInfo {
    display: inline-block;
    vertical-align: middle;
}
.scoreLoading img {
    width: 100px;
}
.scoreLoading {
    margin-right: 50px;
}
.searchField, button {
  display: inline-block;
  vertical-align: top;
}
.searchField {
    height: 34px;
}
.searchField input, .searchField .btn {
    height: 33px;
    margin: 0;
    border-radius: 0;
    display: inline-block;
    vertical-align: top;
}
.searchField input {
    border: 1px solid #a9a9a9;
}
.innerTitle span {
    background: #fff;
    padding: 0 12px;
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    position: relative;
    z-index: 999;
}
.audioAnswer .innerTitle,
.discussion .innerTitle {
    margin: 10px 0 !important;
    position: relative;
}
.audioAnswer .innerTitle:before,
.discussion .innerTitle:before {
    position: absolute;
    content: "";
    background: #f1f1f1;
    height: 1px;
    width: 100%;
    left: 0;
    top: 13px;
}
.discuTabs {
    margin-top: 25px;
}
.discuTabs .nav-tabs {
    text-align: left;
    justify-content: left;
    border-bottom: 1px solid #f1f1f1;
}
.discuTabs .nav-tabs li {
    border: 1px solid #f1f1f1;
    margin: 0 2px;
    border-bottom: 0;
}
.discuTabs .nav-tabs li a {
    color: #757575;
    display: inline-block;
    vertical-align: top;
    padding: 8px 16px;
}
.discuTabs .nav-tabs li a:hover {
    text-decoration: none;
}
.discuTabs .nav-tabs li.active a {
    background: #94C23C !important;
    color: white;
}
.modal-body {
  padding: 24px !important;
}
.modal-body table {
    border-radius: 3px !important;
    overflow: hidden;
    margin: 0;
}
.modal-body table th,
.modal-body table td {
    border: 1px solid #dee2e6;
    padding: 14px 17px;
}
body .modal-footer {
    padding: 0 12px 12px;
    justify-content: center;
    border: none;
}
body .modal-footer button.btn {
    padding: 12px 28px !important;
    font-size: 15px;
    letter-spacing: 2px;
}
.modal-body table th {
    background: rgba(222, 226, 230,0.3);
}
.modal-content {
    border-radius: 5px !important;
}
.modalHeader {
  border-bottom: 1px solid #dee2e6;
  padding: 16px 24px;
}
.modalHeader h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #757575;
    letter-spacing: 1px;
}
.text-danger, a.text-danger:focus, a.text-danger:hover {
    color: #a94044 !important;
}
.p-5px {
  padding: 5px !important;
}
.highlight1 {
  background: blue;
  color: white;
}
.textResult {
  width: 100% !important;
  height: 146px;
  background: white;
}
span.tip {
  border-bottom: 1px dashed;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
}
span.tip:hover {
    cursor: help;
    position: relative;
}
span.tip span {
  display: none;
}
span.tip:hover span {
    padding: 8px;
    display: block;
    z-index: 999;
    background: #ffffff;
    left: 0;
    margin: 0;
    width: 250px;
    position: absolute;
    font-size: 13px;
    bottom: 23px;
    text-decoration: none;
    color: #354555 !important;
    border: 2px solid #dee2e6;
}
.remarksbtn {
  margin: 30px !important;
}
.bl-bg {
    background: rgba(127, 143, 236,0.25);
    color: #2d3fa9 !important;
}
.rd-bg {
  background: rgba(250, 153, 157,0.25);
  color: #a94044 !important;
}
.yl-bg {
    background: rgba(53, 220, 216,0.25);
    color: #1e9693 !important;
}
.carousel .slide .legend {
  position: absolute;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fffefe;
  padding: 10px;
  font-weight: 800;
  font-size: 28px;
  text-align: center;
  opacity: 1 !important;
  background: transparent !important;
}
.crausal-title {
  top: 26% !important;
}
.crausal-body {
  top: 45% !important;
}
textarea {
  min-height: 250px !important;
  border: 1px solid green !important;
}
.commentText{
  min-height: 0px !important;
}
/**/
.input-group-text{padding: 9px !important;width: 40px;background: #cccccc !important;}
.sc-AxhCb > div:focus {border: none;outline: 0;}
.selectPage{background: white;min-width: 70px!important;}
/**/
.list-group-item1 {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group1 {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.scorecard .scoreBlock {
  min-width: 70%;
}
.scoreUserImg .scoreImgOuter {
  background: #cecbc4;
  border: 1px solid #ccc;
  overflow: hidden;
  object-fit: cover;
  object-position: top center;
  height: 167px;
  width: 167px;
  border-radius: 50%;
  margin-top:5%;
}
.scoreUserImg .scoreImgOuter img {
    width: 100%;
    height: 100%;
}
.scoreBlock h3 {
    margin: 0 0 12px;
    line-height: 35px;
}
.scoreBlock h3 span {
  font-weight: bold;
  font-size: 16px;
  display: block;
  width: 100%;
}
.scorecard .scoreBlock .scoreNumber {
    height: 82px;
    width: 82px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    margin: auto auto 10px;
    font-weight: bold;
    line-height: 82px;
    font-size: 22px;
}
.scorecard .scoreBlock .listening .scoreNumber {
    border-color: #a94044;
}
.scorecard .scoreBlock .reading .scoreNumber {
    border-color: #2d3fa9;
}
.scorecard .scoreBlock .speaking .scoreNumber {
    border-color: #1e9693;
}
.scorecard .scoreBlock .writting .scoreNumber {
    border-color: #337182;
}
.scorecard .scoreBlock span {
  font-size: 16px;
}
.scorecard .scoreBlock .listening span {
    color: #a94044;
}
.scorecard .scoreBlock .reading span {
    color: #2d3fa9;
}
.scorecard .scoreBlock .speaking span {
    color: #1e9693;
}
.scorecard .scoreBlock .writting span {
    color: #337182;
}
.overallScore {
    border-top-right-radius: 10px;
    overflow: hidden;
    border-top-left-radius: 10px;
    max-width: 166px !important;
}
.overallScoreInr {
   background: rgba(53, 220, 216,0.25);
   color: #fff;
   padding: 0 !important;
   text-align: center;
   max-width: 145px !important;
   border-bottom-right-radius: 50%;
   border-bottom-left-radius: 50%;
   height: 167px;
   min-width: 145px !important;
}
.overallScore span {
    display: block;
    vertical-align: top;
    background: #1E9693;
    font-size: 15px;
    width: 100%;
    height: 40px;
    font-weight: bold;
    line-height: 40px;
}
.overallScore samp {
    font-size: 50px !important;
    display: flex;
    width: 100%;
    height: 127px;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    font-weight: bold;
    color: #1e9693;
}
.skilsNscore ul {
    padding: 0;
    list-style: none;
    margin: 0;
}
.skilsNscore ul li {
  margin-bottom: 4px;
  padding: 7px;
}
.skilsNscore ul li label,
.skilsNscore ul li div,
.skilsNscore h4 span {
    display: inline-block;
    vertical-align: top;
}
.skilsNscore ul li label,
.skilsNscore h4 span {
    width: 120px;
    text-align: right;
    padding-right: 10px;
}
.skilsNscore ul li > div {
    width: 420px;
    font-weight: bold;
}
.skilsNscore ul li div.numberBar > div.bar {
    width: 388px;
}
.skilsNscore ul li div.bar samp {
  width: 100%
}
.skilsNscore ul li div span,
.skilsNscore ul li div samp {
  display: inline-block;
  vertical-align: middle;
}
.skilsNscore ul li div span {
    padding-right: 10px;
    width: 32px;
    text-align: center;
}
.skilsNscore ul li div samp {
    height: 35px;
    width: 70%;
    background: #94C23C;
}
.skilsNscore ul li.listening div samp {
    background: #a94044;
}
.skilsNscore ul li.reading div samp {
    background: #2d3fa9;
}
.skilsNscore ul li.speaking div samp {
    background: #1e9693;
}
.skilsNscore ul li.writting div samp {
    background: #337182;
}
.skilsNscore h4 {
    margin: 0 0 10px;
    font-size: 15px;
    font-weight: bold;
}
.skillsBreakdown h3, .comucationSkills h3 {
    margin-bottom: 40px;
    font-size: 25px;
    padding-bottom: 18px;
    border-bottom: 1px solid #f1f1f1;
}
.skilsNscore {
    position: relative;
    margin-top: 35px;
    display: inline-block;
    vertical-align: top;
}
.overallBar {
    position: absolute;
    width: 395px;
    height: 100%;
    right: 0;
    top: 0;
}
.overallBar span {
    font-weight: bold;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
}
.overallBar .bar {
    position: absolute;
    width: 6px;
    height: 105%;
    background: #77828c;
    top: -12px;
    margin-left: -5px;
}
.overallBar .score {
    position: absolute;
    top: -43px;
    margin-left: -10px;
}
.skillsBreakdown > div:first-child {
  min-width: 570px;
}
.scoreUserImg {
    max-width: 198px !important;
}
.scorecard .scoreBlock {
    min-width: 480px;
}
.comucationSkills {
    background: #f1f1f1;
    padding: 10px;
}
.scoreFooter,
.scoreHeader {
    background: #f1f1f1;
    padding: 15px;
    text-align: center;
}
.scoreHeader {
    text-align: left;
}
.scoreHeader img {
    height: 100px;
}
.scoreHeader span {
    display: block;
    vertical-align: top;
    font-weight: bold;
    width: 100%;
    font-size: 18px;
}
.scoreFooter a {
    color: #757575;
    font-size: 24px;
    font-weight: bold;
}
.scoreFooter span i {
    font-size: 22px;
    margin-right: 5px;
}
.scoreFooter span.website {
    margin-left: 50px;
}
.sidebar .user .info > a > span, .off-canvas-sidebar .user .info > a > span {
    font-size: 18px;
}
.keyboardImg img {
  height: 240px;
  width: auto;
}
.speakingBar {
    background: #354555;
    color: #fff;
    padding: 20px;
    position: relative;
}
.topArrow {
    position: absolute;
    right: 15px;
    width: auto;
    top: 15px;
}
.topArrow a,
.topArrow a:hover,
.topArrow a:focus {
    color: #fff;
    font-size: 40px;
    line-height: 35px;
}
.topArrow .rightArrow {
    margin-left: 16px;
}
.bottomBtn a {
    background: #94c23c;
    color: #fff;
    margin: auto;
    display: inline-block;
    vertical-align: top;
    font-weight: normal;
    padding: 6px 32px;
    border: 1px solid transparent;
    border-radius: 50px;
    text-transform: capitalize;
}
.bottomBtn a:focus,
.bottomBtn a:hover {
  background: #fff !important;
  color: #94C23C !important;
  border-color: #94C23C !important;
  text-decoration: none;
}
.speakingBar h4 {
    font-size: 20px;
    margin: 0 0 10px;
    padding: 20px 15px 0;
}
.speakingBar h4 i {
    margin-right: 5px;
    font-size: 25px;
}
.numbersList {
    height: 390px;
    text-align: center;
    overflow: auto;
    padding: 0 20px;
}
.numbersList span {
    display: inline-block;
    vertical-align: top;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #94c23c;
    line-height: 37px;
    margin: 3px;
    font-size: 16px;
    font-weight: bold;
}
.nextPrev {
    padding: 15px 5px 9px;
    text-align: center;
}
.nextPrev a {
    color: #fff;
    background: #94c23c;
    margin: 0 7px 7px;
    display: inline-block;
    vertical-align: top;
    padding: 3px 8px;
    border-radius: 35px;
    font-size: 15px;
    font-weight: bold;
}
.nextPrev a.prev i {
    margin-right: 2px;
}
.nextPrev a.next i {
    margin-left: 2px;
}
.nextPrev a:hover,
.nextPrev a:focus {
    color: #94c23c;
    background: #fff;
    text-decoration: none;
}
.quesStatus {
    padding: 10px 12px;
    border-top: 3px solid rgba(255, 255, 255, 0.2);
}
.quesStatus span {
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.quesStatus span:before {
    width: 45px;
    height: 25px;
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
}
.quesStatus .atmptd span:before {
    background: #94C23C;
}
.quesStatus .notAatmptd span:before {
    background: #FF0000;
}
.quesStatus .currentQues span:before {
    background: #337182;
}
.quesStatus > div {
    padding-left: 50px;
    margin-bottom: 7px;
}
.infoBars {
  color: #fff;
  text-align: center;
  margin-bottom: 18px;
}
.infoBars > div {
    padding: 7px;
    font-size: 18px;
}
.infoBars > div i {
    margin-right: 5px;
}
.infoBars .wrongAns {
  background: #FF0000;
}
.infoBars .correctAns {
  background: #94C23C;
}
.infoBars .notSelected {
  background: blue;
}

.timeBlock .timeBlockInr {
  display: inline-block;
  vertical-align: top;
  width: auto;
}
.timeBlock .timeBlockInr {
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 15px 0 5px;
    background: #f1f1f1;
    padding: 4px 25px;
    border-bottom: 15px solid #94c23c;
}
.timeBlock .timeBlockInr p {
    margin: 0;
}
.timeBlockInr i.fa-clock-o {
    font-size: 20px;
    height: auto;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    border: none;
    margin: auto;
}
.connectList {
    list-style: none;
    padding-left: 70px !important;
}
.connectList li {
    position: relative;
    margin-bottom: 10px;
}
.connectList li:before {
    content: "";
    position: absolute;
    left: -40px;
    top: 66%;
    height: 70px;
    width: 40px;
    border: 4px solid #354555;
    border-right: 0;
    z-index: 0;
}
.connectList li:after {
    content: "\f00c";
    position: absolute;
    left: -70px;
    top: 88%;
    height: 25px;
    width: 25px;
    color: #94c23c;
    font-family: fontawesome;
    font-size: 23px;
}
.connectList li.cross:after {
    content: "\f00d";
    color: red;
}
.connectList li:last-child:before,
.connectList li:last-child:after {
    display: none;
}
.connectList li span {
  padding: 12px 10px;
  color: #fff;
  display: inline-block;
  width: 100%;
  background-color: #94C23C;
  min-height: 85px;
  position: relative;
}
.comucationSkills h3 {
  margin-bottom: 0;
}
.languageLogo {
  text-align: center;
}
.languageLogo img {
  height: 80px;
  width: auto;
}
.userInfoCard {
    border-radius: 10px !important;
    box-shadow: rgba(51, 51, 51, 0.08) 0px 4px 10px;
    color: #505050;
    overflow: hidden;
}
.userInfoCard .cardHeader {
    padding: 12px 17px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
.userInfoCard .cardHeader > div {
    width: 50%;
    float: left;
}
.userInfoCard .cardHeader > div:last-child {
    text-align: right;
}
.userInfoCard .cardHeader > div:last-child span {
    border: 1px solid #1e9693;
    border-radius: 3px;
    padding: 4px 10px;
}
.userInfoCard.green .cardHeader {
    background: rgba(53, 220, 216,0.25);
    color: #1e9693;
}
.userInfoCard.purple .cardHeader {
    background-color: rgba(127, 143, 236,0.25);
    color: #2d3fa9;
}
.userInfoCard .cardBody {
    padding: 35px 25px;
    text-align: center;
}
.userInfoCard .cardFooter {
    padding: 0 14px 14px;
    text-align: right;
}
.userInfoCard .cardBody .col span {
    display: block;
    width: 100%;
    font-size: 35px;
    color: #000;
    line-height: 35px;
}
.userInfoCard .cardFooter a i {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}
h3.cardTitle {
    margin: 0 0 5px;
    font-size: 22px;
}
.testRecord.speaking {
    background-color: #f480a7;
}
.testRecord.writing {
  background-color: #506cf9;
}
.testRecord.reading {
  background-color: #30bd91;
}
.testRecord.listening {
  background-color: #1d145b;
}
.testRecord {
    border-radius: 28px;
    margin-bottom: 12px;
}
.testRecord h4 {
    margin: 0 0 4px;
    font-size: 18px;
    font-weight: bold;
}
.testRecord h4 a {
    color: #fff;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 20px;
}
.testRecord h4 a:hover {
    text-decoration: none;
}
.testRecord span {
    display: block;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    line-height: 20px;
}
.testRecord span.doneQues {
    font-size: 15px;
}
.videoOuter iframe {
  width: 100%;
  height: auto;
  min-height: 250px;
}
.videoOuter h5 {
    margin: 0 0 5px;
    font-size: 18px;
    font-weight: bold;
}
.recentVideos h3 {
    font-size: 22px;
    margin: 0 0 30px;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 5px;
}
.recentVideos .nav-tabs {
    justify-content: left;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 30px;
}
.recentVideos .nav-tabs li.nav-item a.nav-link {
    border: none !important;
    color: #9A9A9A !important;
    text-transform: uppercase;
    padding: 6px 16px;
}
.recentVideos .nav-tabs li.nav-item a.nav-link.active {
    background: transparent !important;
}
.recentVideos .nav-tabs li.nav-item a.nav-link.active:before {
    display: none;
}
.recentVideos .nav-tabs li.nav-item a.nav-link.active {
    background: transparent !important;
    color: #94c23c !important;
}
.recentVideos .nav-tabs li.nav-item a.nav-link.active:after {
    content: "";
    left: 0;
    right: inherit;
    bottom: 0;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #94c23c;
}
.nav-item {
  cursor: pointer;
}

.selected-index-result{
  background: white !important;
  color: black !important;
}

.nav-pills-primary .nav-item .nav-link {
    border: 1px solid #51cbce !important;
    /*color: #51cbce !important;*/
}
.nav-pills-primary .nav-item .nav-link.active {
    border: 1px solid #51cbce !important;
    background: #51cbce !important;
    /*color: #FFFFFF !important;*/
}

.text-orange{color: #ffcd72;}
.iyKrJgd{
  width: 14px;
  height: 14px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  margin-left: 7px;
  background: rgb(64, 223, 143);
}
.iyKrJav{
  width: 14px;
  height: 14px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  margin-left: 7px;
  background: orange;
}
.iyKrJbad{
  width: 14px;
  height: 14px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  border-radius: 100%;
  background: red;
}
.liAXDW{display: flex;}
.practice-div{margin-bottom: 15px;}
.card-body .card{border-top: 15px solid white !important;box-shadow: none;}
.practice-div button{border-radius: 0px !important;background: grey !important;width: 100% !important;}
.carousel-root {
    width: 100%;
}
.carousel .slide img {
    width: auto;
    height: 500px;
}
.carousel .slide .legend {
    position: absolute;
    left: 0;
    border-radius: 10px;
    color: #fffefe;
    padding: 10px;
    font-weight: 800;
    font-size: 28px;
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.4) !important;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
}
.slide .legend h3  {
  font-weight: bold;
  font-size: 25px;
  margin: 0;
}
#anouncementPopup.modal {
  font-family: 'Poppins', sans-serif,Helvetica Neue,Arial,sans-serif;
}
#anouncementPopup.modal .close {
  position: absolute;
  padding: 0;
  right: 0;
  top: -35px;
  color: #a4a4a4;
  font-size: 30px;
  font-weight: 500;
  line-height: 15px;
}
#anouncementPopup.modal .close:focus {
  outline: 0;
}
#anouncementPopup .modal-dialog {
  max-width: 800px;
}
#anouncementPopup .modal-content {
  background-color: #fff;
  border-radius: 0;
  background-image: url(/images/popup-bg.jpg);
  background-size: cover;
  background-position: top center;
  text-align: center;
}
/* #anouncementPopup .modal-content {
  background-color: #fff;
  border-radius: 0;
  background-image: url(/images/popup-bg-1.jpg);
  background-size: cover;
  background-position: left -151px;
  text-align: center;
} */
#anouncementPopup .modal-header {
  border-bottom: 0;
  background: transparent;
}
#anouncementPopup .modal-title {
  font-size: 22px;
  font-weight: 600;
  color: #18294d;
}
#anouncementPopup .modal-body {
  position: relative;
  padding: 0;
}
#anouncementPopup .modal-body img {
  max-width: 100%;
}
#anouncementPopup .contentInr {
  width: 670px;
  margin: auto;
}
#anouncementPopup .contentInr button {
  padding: 18px 50px;
  background: linear-gradient(#95c33b, #9fdd2a);
  background: -webkit-linear-gradient(#95c33b, #9fdd2a);
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  color: #fff;
  margin-top: 40px;
}
#anouncementPopup .contentInr button:hover,
#anouncementPopup .contentInr button:focus {
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
}
.mobileIcon {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.mobileIcon img {
  height: 108px;
  width: auto;
}
.contentInr h2 {
  text-transform: uppercase;
}
.mockPlans {
  padding: 50px 0 55px;
}
.planOuter {
  border: 1px solid #e5e5e5;
  max-width: 525px;
  margin: auto;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.planOuter .planHeader {
  padding: 25px;
  background: #94c23c;
  color: #fff;
}
.planHeader h2 {
    font-size: 52px;
    margin: 0;
}
.planHeader h2 span {
    display: block;
    vertical-align: top;
    width: 100%;
    text-transform: uppercase;
    font-size: 17px;
}
.planOuter li {
    margin: 0;
    padding: 0;
}
.planOuter li {
    padding: 17px;
    list-style: none;
    font-size: 14px;
    font-weight: bold;
}
.planOuter li:first-child {
    padding: 34px 25px 25px 25px;
}
.planOuter li:last-child {
    padding: 25px 25px 50px;
}
.planOuter li i {
    color: #94c23c;
    font-size: 14px;
    margin-right: 8px;
}
.planOuter li:nth-child(2n) {
    background: #f5f8f9;
}
.planOuter .themeBtn {
    padding: 14px 38px;
    margin-bottom: 25px;
}
.planOuter li a {
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #e3e3e3;
    padding: 3px 15px;
    border-radius: 4px;
    color: #222;
}
.planOuter .progressNo span {
    display: inline-block;
    vertical-align: middle;
    width: 22%;
}
.progressBar {
    position: relative;
  text-align: left;
  margin-bottom: 10px;
}
.planOuter .progressBar i {
    z-index: 9999;
    background: #fff;
    position: relative;
    border: 1px solid #e5e5e5;
    top: -8px;
    color: #787878;
    padding: 5px 12px;
    border-radius: 3px;
}
.rangeSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 4px;
}
.rangeSlider:hover {
  opacity: 1;
}
.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 45px;
  height: 25px;
  background: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.1);
}
.rangeSlider::-moz-range-thumb {
  width: 45px;
  height: 25px;
  background: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.1);
}
.grnbtn{
  background: linear-gradient(#95c33b, #9fdd2a);
    background: -webkit-linear-gradient(#95c33b, #9fdd2a);
    color: white;
}
.plan-detail{
  
  font-size: 16px;
  margin-right: 8px;
}

.grn-color{color: #94c23c;}
.ReactChart,.ReactChart > svg {width: 110% !important}
.text{margin-bottom: 10},
.instructionsCard{
    margin-bottom: 20;
    margin-top: 20;
    width: '100%';
    margin: 'auto';
  }
.sidebar .nav li.active > a + div .nav li > a, .off-canvas-sidebar .nav li.active > a + div .nav li > a {
  color: #162545;
  opacity: 1;
}
.devideTitle.card-header {
    display: flex;
    flex-direction: row;
}
.devideTitle.card-header > div {
    margin-left: auto;
}
.devideTitle.card-header > div a.nav-link {
    padding-left: 0;
    padding-right: 0;
}
.nav-link.buttonLink {
  display: inline-block;
  vertical-align: top;
  background: #94C23C;
  margin: 0 6px;
  font-weight: bold;
  padding: 12px 32px;
  border: 1px solid transparent;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  color: #fff;
}
.nav-link.buttonLink:hover {
  border-color: #94C23C;
  color: #94C23C;
  background: #fff;
}
.nav-link.buttonLink p {
  font-size: 12px;
  margin: 0;
  line-height: 20px;
}
.nextPrevOuter button.btn-icon {
    width: auto;
    padding: 8px 18px;
    height: auto;
}
.nextPrevOuter button.btn.btn-icon i.nc-icon {
    position: initial;
    -webkit-transform: none;
    transform: none;
    margin: auto;
    font-size: 12px;
    width: auto;
}
.nextPrevOuter button.btn.btn-icon span {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 3px auto;
    font-size: 14px;
}
.waveformVolume {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}
.waveContainer {
    width: 92%;
    background: #f2f3f3;
}
.waveformVolume button.btn {
    background: transparent;
    padding: 0 !important;
    border: none;
}
.waveformVolume button.btn:hover {
  border: none;
}
#waveformContainer i.fa.fa-volume-up {
    font-size: 18px;
    border: none;
    margin: 0;
    width: auto;
    height: auto;
    color: #94c23c;
}
.waveButtons {
    margin-top: 10px;
}
.waveButtons i.fa {
    height: 40px;
    width: 40px;
    font-size: 12px;
    margin: 0;
    border: 1px solid #000;
    color: #000;
}
.waveButtons i.fa:hover {
    background: #000;
    color: #fff;
}
.waveButtons button {
  margin: 0 2px;
}
.waveButtons button + button {
  margin-left: 2px;
}
.waveButtons button.btn:hover, .waveButtons button.btn:focus {
    background: transparent !important;
}
.waveLeftButton {
    position: relative;
}
.volumeControl {
    position: absolute;
    height: 75px;
    left: 8px;
    top: -27px;
}
.waveformVolume >p {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    margin: 0 !important;
    line-height: 17px;
    font-size: 12px;
    text-align: right;
    max-width: 120px;
}
.userCatOuter {
    padding: 45px 25px;
    display: flex;
    flex-direction: row;
}
.userInfo {
    width: 260px;
    text-align: center;
    position: relative;
    padding-right: 45px;
}
.userInfo:after {
    position: absolute;
    right: 0;
    width: 2px;
    height: 80%;
    background: #b5b5b5;
    content: "";
    top: 45px;
    bottom: 0;
}
.userInfo h4.userName {
    margin: 0;
    color: #1d195b;
    font-size: 20px;
}
.dashUserImgOtr {
    height: 118px;
    width: 118px;
    border-radius: 100px;
    overflow: hidden;
    border: 7px solid #b9ed2e;
}
.dashUserImgOtr img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}
span.premiumLogo {
    position: absolute;
    top: -8px;
    right: 2px;
    height: 35px;
    width: 35px;
    border-radius: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
span.premiumLogo img {
    height: auto;
    width: 24px;
}
.catRight {
    padding-left: 35px;
    width: 80%;
    position: relative;
    padding-bottom: 40px;
}
.catRight .greenBtn {
    position: absolute;
    bottom: 0;
    right: 0;
}
.dashTitle {
    margin: 0;
    font-size: 27px;
    color: #010101;
    font-weight: 500;
    position: relative;
}
.dashTitle:after,
.deviceTestPage h4:after,
.card .card-header .card-title:after,
.innerTitle:after {
    position: absolute;
    content: "";
    background: #b5b5b5;
    height: 3px;
    width: 90%;
    right: 0;
    top: 50%;
}
.dashTitle span,
.dashTitle span,
.deviceTestPage h4 span,
.card .card-header .card-title span,
.innerTitle span {
    background: #fff;
    padding-right: 30px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 99;
}
.input-group-append .btn-primary {
    display: block;
    vertical-align: top;
}
.dashUserImgBlk {
    width: 118px;
    margin: auto;
    height: 118px;
    position: relative;
}
.catRight .catItems {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 45px;
    margin-bottom: 45px;
}
.userRecord a.nav-link {
    padding: 0.5rem 0rem;
}
.catRight .catItems .userRecord a.nav-link p {
    margin: 0;
    font-size: 16px;
    color: #3f8dc8;
}
.greenBtn {
    margin-top: 20px;
    text-align: right;
}
.greenBtn a {
    background: #30bd91;
    color: #fff;
    border-radius: 30px;
    font-weight: 500;
    font-size: 17px;
    padding: 12px 30px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}
.greenBtn a:hover {
    opacity: 0.7;
    color: #fff;
    text-decoration: none;
}
.radiusBlk {
  background: #fff;
  border-radius: 25px;
  padding: 25px 30px !important;
}
.userCatOuter.radiusBlk {
    background-image: url('/images/categories-bg.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
}
.sideVideoOuter {
    padding: 15px 0 0;
}
.videosList {
    overflow: auto;
    height: 76vh;
    padding-right: 12px;
}
.testAtemptBlk {
  margin: 25px 0 0;
}
.examCalenderInr {
    background-color: #2198cd;
    border-radius: 28px;
    padding: 25px 45px !important;
    background-image: url('/images/calender-img.png');
    background-repeat: no-repeat;
    background-position: 90% 85%;
    background-size: 190px;
    height: 100%;
}
.examDate {
    border-bottom: 2px solid #a4ecef;
    padding-bottom: 30px;
    margin-bottom: 32px;
}
.examCalenderInr h2 {
    color: #feff9c;
    font-size: 33px;
    font-weight: 600;
    margin: 0;
}
.examCalenderInr h2 span {
    color: #fff;
    font-size: 22px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
.upcmngClasBlkInr {
    padding: 25px 30px;
}
.upcmngClasBlkInr  table {
    width: 100%;
}
.upcmngClasBlkInr  table th {
    padding-bottom: 20px;
}
.upcmngClasBlkInr  table th span {
    background: #2198cd;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 26px;
    font-weight: 600;
}
.upcmngClasBlkInr  table th.upcomingHead span {
    background: #30bd91;
    padding: 12px 27px;
}
.upcmngClasBlkInr  table tr td {
    padding: 6px 0;
    font-size: 16px;
    color: #1d145b;
}
.dashRightSidebarInr {
  height: 100%;
}
.sidebar .logo, .off-canvas-sidebar .logo {
    padding: 35px 0.7rem 30px;
}
.sideVideoOuter img {
    height: auto;
    width: 100%;
}
body .sidebar, body .off-canvas-sidebar {
    width: 370px;
}
body .main-panel {
    width: calc(100% - 370px);
}
body .sidebar .sidebar-wrapper, 
body .off-canvas-sidebar .sidebar-wrapper {
    width: 370px;
}
body .wrapper .sidebar .nav li > a + div {
  background: transparent;
}
body .sidebar .nav li > a, 
body .off-canvas-sidebar .nav li > a,
body .sidebar .nav i,
body .off-canvas-sidebar .nav i {
  opacity: 1;
}
body .sidebar .nav .caret,
body .off-canvas-sidebar .nav .caret {
    top: 4px !important;
    position: inherit;
    display: inline-block;
    vertical-align: middle;
    margin-left: 28px;
}
body .wrapper .sidebar .nav p,
body .wrapper .off-canvas-sidebar .nav p {
    display: flex;
    padding-left: 0;
    flex-direction: row;
    align-items: flex-start;
}
body .caret {
    border-top: 8px dashed;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}
body .wrapper .sidebar .nav li > a + div i {
    font-size: 18px !important;
}
body .wrapper .sidebar .nav li > a + div {
    padding: 4px 4px 4px 22px;
}
body .sidebar .logo-img img {
    height: 182px;
    max-width: 100% !important;
}
.sideVideoOuter iframe {
  height: 150px;
  width: 100%;
}
.userInfo .btn {
    border-radius: 50px;
}
.sidebar .nav li > a, .off-canvas-sidebar .nav li > a {
  font-size: 13px !important;
  font-weight: bold !important;
}
.examDaysLeft .btn {
    border-radius: 50px;
    margin: 0;
}
.dashRightSidebar {
/*   max-width: 349px !important;
    margin-left: auto;*/
}
.descTransTab {
    border-top: 1px solid #d3d3d3;
    margin-top: 45px;
}
.descTransTab h3 {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.descTransTab i.fa {
    font-size: 15px;
}
.descTransCntnt {
    margin-top: 10px;
}
.descTransCntnt h4 {
    font-size: 14px;
    margin: 0;
}
.btn-info {
  background-color: #1d195b;
}
.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper {
    position: relative;
    height: auto;
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 0; 
    height: auto;
    padding-bottom: 5px !important;
}
.sidebar-mini .dashLeftCntnt {
    transition: all 0.2s ease-in-out 0s;
    -webkit-transition: all 0.2s ease-in-out 0s;
}
.catRight .catItems .userRecord {
    max-width: 33%;
    flex: none;
}
.userInfo a.btn {
    padding-top: 8px;
    width: 100%;
    padding-bottom: 8px;
    margin: 10px auto auto;
}
.examDaysLeft button.btn-round.btn {
    background: #f89646;
}
.examDaysLeft button.btn-round.btn:hover {
    color: #f89646 !important;
    border-color: #f89646 !important;
}
.sidebar-mini .collapseNavIcon img {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.userInfo h4.userName a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.userInfo h4.userName a {
    color: #1d195b;
}
button.bottom-btn.playBtn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
button.bottom-btn.playBtn .fa-play {
    margin: 0;
}
#recorded_waveform, #recording_waveform {
  margin-left: auto !important;
}
.collapseNavIcon img {
    height: 25px;
    width: auto !important;
}
.paginationOuter button,
.btn.btn-info {
    background: #94c23c !important;
    border-color: #94c23c !important;
}
body .form-control[readonly] {
  background: #fff;
}
.descriptionCard {
    padding: 0 !important;
}
button.btn-icon.btn-round.btn.btn-default:active i,
button.btn-icon.btn-round.btn.btn-default:hover i,
button.btn-icon.btn-round.btn.btn-default:focus i {
    color: #94c23c;
}
.videosBlock h5 {
    font-size: 17px;
    font-weight: 500;
}
.videosBlock p {
    font-size: 13px;
}
.dashboardCntnt  {
    justify-content: center;
}
.sidebar-wrapper {
    height: auto;
    overflow: auto;
}
body .card label {
    font-size: 14px;
    color: #010101;
    font-family: 'Poppins', sans-serif;
}
.form-group .form-control, .input-group .form-control {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.card .card-footer .btn {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.card-body select.form-control {
    height: auto !important;
    padding: 10px;
}
.table-responsive td {
    width: 12%;
    text-wrap: balance;
}
.instructionsTitle.card-title {
    margin: 40px 0 0;
}
.instructionsTitle.card-title h5 {
    font-weight: 500;
    color: #505050;
    font-size: 18px;
    margin: 0;
}
.devideTitle h4.card-title:after {
  display: none;
}
.responseBlock {
    border-bottom: 1px solid #dbdcdd;
    padding-bottom: 30px;
}
.responseBlock h3.pageTitle {
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 500;
}
.translatedText h4 {
  font-size: 16px;
}
.table th.w-50, .table td.w-50 {
    width: 30% !important;
}
#exitPopup .card-title h3,
#startPopup .card-title h3,
.modal-content .card-title h3 {
    margin-bottom: 0;
    font-weight: 500;
}
#exitPopup .card-body h4,
#startPopup .card-body h4,
.modal-content .card-body h4 {
    margin-top: 0;
    font-size: 18px;
}
.devideTitle.card-header button.btn {
    margin-bottom: 0 !important;
}
.smallContainer h5 {
    font-size: 18px;
    font-weight: 500;
}
.successContent h4 {
    position: relative;
    margin-bottom: 20px !important;
}
.successContent div#recorded_waveform {
    margin-left: auto !important;
}
.successContent div#timelineContainer,
.unselectable div#timelineContainer {
    padding-left: 0;
    width: calc(100% - 45px);
    margin-left: auto;
    font-size: 14px;
}
.red-color {
  color: #fe0000;
}
.sideVideoOuter iframe {
  height:240px;
}
span.segmentCount {
    min-width: 35px;
    display: inline-block;
    vertical-align: middle;
}
.exam_date_container  label {
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
select.form-control {
    -webkit-appearance: none;
    background-image: url('/images/icon/down-arrow.png');
    background-repeat: no-repeat;
    background-position: 98% center;
    background-size: 18px;
    cursor: pointer;
}
body .sidebar .nav li > a, .off-canvas-sidebar .nav li > a {
    margin: 10px 0 0;
}
.perfect-scrollbar-on .sidebar,
.perfect-scrollbar-on .main-panel {
    min-height: 100%;
}
.testCatList {
    background: #1d195b;
}
.testCatList  > label {
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.testCatList ul {
    margin: 0;
    list-style: none;
}
.testCatList ul li {
    cursor: pointer;
    font-size: 14px;
    padding: 12px 20px;
    border-bottom: 1px solid #171442;
    background: #1d195b;
    color: #fff;
}
.testCatList ul li:hover,
.testCatList ul li.active {
    background-color: #ffffff;
    color: #1d195b;
}
.testCatList ul li:last-child {
    border-bottom: 0px solid #f3f3f3;
}
.testResult {
    padding-left: 30px;
    padding-top: 15px;
}
.testResult h4.testResultTitle {
  font-weight: 500;
}
.card-body .testContainer.card {
    border: 0px solid #ddd !important;
}
.translationCntnt span {
    color: #505050;
    font-size: 16px;
    font-weight: normal;
    margin-right: 8px;
}
.translationCntnt h5 {
    font-size: 16px;
    margin: 6px 0 15px;
}
.translationCntnt {
    margin-bottom: 40px;
}
.testContainer button.btn {
    padding: 8px 18px;
}
.testContainer .card-body {
    padding: 0 !important;
    text-align: center;
}
#exitPopup .card-body .popupCatName {
    display: inline-block;
    vertical-align: top;
    padding: 3px 12px;
    border: 1px solid #ddd;
    margin: 5px;
    border-radius: 3px;
    background: #f9f9f9;
}
#exitPopup .card-body h4 {
    margin: 0 0 2px;
    font-size: 15px;
    font-weight: 500;
}
#exitPopup .card-title h3 {
    font-size: 24px;
}
.vocacbTestCntnt {
    border: 1px solid #1d195b;
}
.vocabTestOuter h4.card-title:after {
  display: none;
}
.testContainer  .card-body h5 {
    font-weight: bold;
}
.testBottomBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.testResultCntnt span {
  display: block;
  width: 100%;
}
.testResultCntnt img {
    height: 110px;
    width: auto;
    margin-bottom: 15px;
}
.testResultCntnt span {
    font-weight: 500;
    margin: 6px 0;
}
.testResultCntnt  p {
    font-size: 18px;
    font-weight: 500;
}
.speakerImg {
    margin-bottom: 30px;
}
.speakerImg img {
    transform: rotate(-17deg);
    -webkit-transform: rotate(-17deg);
}
.buyBlurContainer {
    position: relative;
}
.buyBlurContainer img {
    width: 100%;
}
.seeMoreVocab {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffad;
    flex-direction: column;
    height: 100%;
}
.seeMoreVocab h4 {
    color: #010101;
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 14px;
}
.noRecord {
  text-align: center;
}
.noRecord img {
  width: 300px;
  height: auto;
  margin: 20px 0;
}
.testInstructions p i {
    font-weight: bold;
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
}
.testResult select:focus {
  background: #fff;
}

/*media start*/
@media screen and (max-width: 1800px) {
.userCatOuter.radiusBlk {
    background-size: 65%;
}
body .sidebar .logo-img img {
    height: 140px;
}

}
@media screen and (max-width: 1600px) {
.userRecord a.nav-link {
    padding: 0.5rem 0;
}
.examCalenderInr h2 {
    font-size: 28px;
    line-height: 30px;
}
.examCalenderInr h2 span {
    font-size: 22px;
}
.greenBtn a {
    padding: 10px 30px;
}
.testRecord h4 a {
    padding: 14px;
}
.examCalenderInr {
    padding: 25px 30px !important;
    background-size: 120px;
}
.userCatOuter.radiusBlk {
    background-size: 60%;
}
.catRight .catItems {
    margin-top: 25px;
    margin-bottom: 25px;
}
.examDaysLeft button.btn-round.btn {
    padding: 10px 22px;
}

}
@media screen and (max-width: 1440px) {
.topArrow a, .topArrow a:hover, .topArrow a:focus {
    font-size: 32px;
}
.topArrow {
    top: 20px;
}
body .sidebar, body .off-canvas-sidebar,
body .sidebar .sidebar-wrapper, body .off-canvas-sidebar .sidebar-wrapper {
    width: 270px;
}
body .main-panel {
    width: calc(100% - 270px);
}
.catRight .catItems {
    margin-left: -1rem;
    margin-right: -1rem;
}
.catRight .catItems .userRecord a.nav-link p {
    font-size: 14px;
    line-height: 20px;
}
body .sidebar .logo-img img {
    height: 120px;
}
body .sidebar .logo, body .off-canvas-sidebar .logo {
    padding: 37px 0.7rem 20px;
}
.userInfo h4.userName {
    font-size: 17px;
}
.userInfo {
    width: 230px;
    padding-right: 25px;
}
.catRight {
    padding-left: 25px;
    width: 100%;
}
.dashTitle,
.deviceTestPage h4,
.card .card-header .card-title,
.innerTitle {
    font-size: 24px;
}
.dashTitle:after,
.deviceTestPage h4:after,
.card .card-header .card-title:after,
.innerTitle:after {
    height: 2px;
}
.testRecord h4 {
    font-size: 15px;
}
.examCalenderInr h2 span {
    font-size: 18px;
}
.examCalenderInr h2 {
    font-size: 25px;
}
.examCalenderInr {
    padding: 25px 30px !important;
}
.testAtemptBlk .row .col-md-6.testAttempts {
    max-width: 42%;
    flex: none;
}
.testAtemptBlk .row .col-md-6.examCalender {
    max-width: 58%;
    flex: none;
}
.testRecord h4 a {
    padding: 12px;
}
.upcmngClasBlkInr table th span {
    padding: 10px 20px;
    font-size: 14px;
}
.upcmngClasBlkInr table th.upcomingHead span {
    padding: 10px 27px;
}
.upcmngClasBlkInr table tr td {
    font-size: 14px;
}
.examDate {
    padding-bottom: 20px;
    margin-bottom: 22px;
}
.wrapper .sidebar .nav li>a {
    padding: 10px 23px;
}
.wrapper .sidebar .nav p, .wrapper .off-canvas-sidebar .nav p {
    font-size: 13px;
}
body .sidebar .nav i, body .off-canvas-sidebar .nav i {
    font-size: 16px !important;
    width: 22px;
}
body .sidebar .nav li > a + div .nav li > a,
body .off-canvas-sidebar .nav li > a + div .nav li > a {
    margin-top: 0px;
}
body .sidebar .nav .caret,
body .off-canvas-sidebar .nav .caret {
    top: 7px !important;
}
body .caret {
    border-top: 6px dashed;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}
.radiusBlk {
    padding: 20px !important;
}
.greenBtn a {
    font-size: 15px;
    padding: 9px 30px;
}
.userRecord a.nav-link {
    padding: 0.8rem 0;
}
.sideVideoOuter iframe {
    height: 190px;
}

}
@media screen and (max-width: 1366px) {
.numbered-ul li {
  margin: 0 .6% 0 0;
  font-size: 12px;
  min-width: 21.4px;
}
.skillsBreakdown .infoBlock {
  max-width: 40%;
}
.numbersList {
  padding: 0 10px;
}
.numbersList span {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 14px;
}
.speakingBar h4 {
    font-size: 20px;
    margin: 0 0 10px;
    padding: 15px 15px 0;
}
.speakingBar h4 i {
    font-size: 22px;
}
.nextPrev {
    padding: 15px 2px;
}
.nextPrev a {
    margin: 0 3px 7px;
    font-size: 13px;
}
.quesStatus span {
    font-size: 15px;
}
.quesStatus span:before {
    width: 38px;
    height: 20px;
    top: 4px;
}
.quesStatus > div {
    padding-left: 40px;
    margin-bottom: 3px;
}
.quesStatus span:before {
    width: 20px;
    height: 17px;
    top: 5px;
    left: -30px;
}
.topArrow {
  top: 13px;
}
.topArrow a, .topArrow a:hover, .topArrow a:focus {
    font-size: 30px;
}
.topArrow .rightArrow {
    margin-left: 7px;
}
.speakingStatus .doubleImges .col-sm-6 {
    max-width: 100%;
    flex: auto;
    margin: 0 0 10px;
}

}
@media screen and (max-width: 1300px) {
.numbered-ul li {
    margin: 0 .6% 0 0;
    font-size: 12px;
    min-width: 21.4px;
}
.graph-section .left-panel {
  width: 50%;
}
.graph-section .right-panel {
  width: 100%;
}
.graph-section .right-panel .skill-right {
    width: 540px;
    float: left;
    margin-left: 25px;
}

}
@media screen and (max-width: 1200px) {
.skillsBreakdown > div:first-child {
    min-width: 580px;
}
.infoBlock {
    margin-top: 35px;
}
.quesStatus span:before {
    width: 38px;
    height: 20px;
    top: 4px;
}
.quesStatus > div {
    padding-left: 26px;
    margin-bottom: 3px;
}
.quesStatus span:before {
    width: 20px;
    height: 17px;
    top: 5px;
    left: -30px;
}
.numbersList span {
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
}
.speakingBar h4 {
    font-size: 16px;
}
.topArrow a, .topArrow a:hover, .topArrow a:focus {
    font-size: 20px;
}
body .sidebar,
body .off-canvas-sidebar,
body .sidebar .sidebar-wrapper,
body .off-canvas-sidebar .sidebar-wrapper {
    width: 230px;
}
body .main-panel {
    width: calc(100% - 230px);
}
body .sidebar .logo-img img {
    height: 100px;
}
body .sidebar .logo, body .off-canvas-sidebar .logo {
    padding: 37px 0.7rem 10px;
}
.userRecord a.nav-link {
    padding: 0.5rem 0.8rem;
}
.dashTitle {
    font-size: 20px;
}
.examCalenderInr h2 {
    font-size: 21px;
    line-height: 24px;
}
.examCalenderInr h2 span {
    font-size: 16px;
}
body .sidebar .logo, body .off-canvas-sidebar .logo {
    padding: 17px 0.7rem 20px;
}
.sideVideoOuter iframe {
    height: 150px;
}
.testRecord h4 a {
    padding: 10px;
    font-size: 13px;
}
.userInfo h4.userName {
    font-size: 15px;
}
.premiumUserbtn {
    padding: 8px;
}
.userInfo .upgradeBtn {
    padding-top: 9.2px !important;
    padding-bottom: 9.2px !important;
}
.greenBtn a {
    font-size: 14px;
    padding: 8px 30px;
}

}
@media (min-width: 992px) {
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before,
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after {
    display: none !important;
}

}